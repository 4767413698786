/**
 *
 * Integrations/Webinarjam/AvailableWebinars
 *
 */

import React, { useMemo, useState } from 'react'

// NPM Libraries
import FloatingLabel from '@joeyparis/react-floating-label-input'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { format } from 'date-fns'

// Graphql
import useQuery from 'components/UseQuery'
import { gql, useMutation } from '@apollo/client'

// Components
import Modal from 'components/Modal'
import TabsBar from 'components/TabsBar'
import Tabs from 'components/Tabs'
import Spinner from 'components/Spinner'
import WebinarIcon from 'components/Campaigns/Webinar.svg'

import bigmarker_logo from 'components/Integrations/BigMarker/complete-logo.png'
import webinarjam_logo from 'components/Integrations/Webinarjam/complete-logo.png'

// Utils
import { COLORS } from 'global-styles'

const SelectedContainer = styled.div`
	width: 90%;
	display: flex;
	align-items: center;

	button {
		color: inherit;
		font-size: 1.2rem;
		margin: 0;
	}

	p {
		margin-left: 15px;
	}
`

const TabButton = styled.a`
	cursor: pointer;
`

const List = styled.div`
	max-height: 400px;
	overflow-y: auto;
	border: 1px solid ${COLORS.borderGrey};
	border-top: 0;
`

const ListElement = styled.div`
	cursor: pointer;
	padding: 15px 20px;
	background: transparent;
	display: block;
	width: 100%;
	border-bottom: 1px solid ${COLORS.borderGrey};

	h5 {
		font-weight: bold;
		margin-bottom: 10px;
	}

	&:hover {
		background-color: ${COLORS.lightGrey};
	}
`

const GET_AVAILABLE_WEBINARS = gql`
	query getAvailableWebinars($user_id: ID!) {
		user(id: $user_id) {
			id
			available_webinars
		}
	}
`

const REFRESH_LIST = gql`
	mutation bigmarkerRefreshWebinars($user_id: ID!) {
		bigmarkerRefreshWebinars(input: { user_id: $user_id }) {
			done
		}
	}
`

function BigmarkerSingeWebinarItem({ webinar, start_time }) {
	const webinar_start_time = start_time || new Date(webinar.start_time.slice(0, -6))

	return (
		<div className="d-flex flex-column">
			<span className="">
				Webinar ID:{' '}
				<a href={webinar.url} target="_blank" onClick={(e) => e.stopPropagation()}>
					<b>{webinar.id}</b>
				</a>
			</span>

			<span>
				Date: <b>{format(webinar_start_time, 'EEEE MMM d')}</b>
			</span>

			<span>
				Time: <b>{format(webinar_start_time, 'h:mm a')}</b>
			</span>
		</div>
	)
}

function BigmarkerRecurrentWebinarItem({ webinar }) {
	return (
		<div style={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: '10px' }}>
			{webinar.child_webinars.map((child_webinar) => {
				const start_time = new Date(child_webinar.start_time.slice(0, -6))

				return (
					<div key={child_webinar.id} className="border py-2 px-3 d-flex flex-column">
						<span className="mb-3">
							Webinar ID:{' '}
							<a href={child_webinar.url} target="_blank" onClick={(e) => e.stopPropagation()}>
								<b>{child_webinar.id}</b>
							</a>
						</span>
						<span>
							Date: <b>{format(start_time, 'EEEE MMM d')}</b>
						</span>
						<span>
							Time: <b>{format(start_time, 'h:mm a')}</b>
						</span>
					</div>
				)
			})}
		</div>
	)
}

function BigMarkerList({ list, user_id, handleChange, refetch }) {
	const { single_webinars, recurring_webinars } = list
	const [hash, setHash] = useState(0)
	const [search_string, setSearchString] = useState('')

	const [refresh, { loading }] = useMutation(REFRESH_LIST, { variables: { user_id }, onCompleted: () => refetch() })

	const filter_title_and_id = (webinar) => {
		const title_and_id = `${webinar.title.toLowerCase()}+${webinar.id}`

		return title_and_id.includes(search_string.toLowerCase())
	}

	const filtered_single_webinars = single_webinars?.filter(filter_title_and_id)
	const filtered_recurring_webinars = recurring_webinars?.filter(filter_title_and_id)

	return (
		<React.Fragment>
			<div className="d-flex justify-content-center mt-2" style={{ marginBottom: '-10px' }}>
				<TabsBar index={hash}>
					<div className="clickable" onClick={() => setHash(0)}>
						Single Webinars
					</div>
					<div className="clickable" onClick={() => setHash(1)}>
						Recurring Webinars
					</div>
				</TabsBar>
			</div>
			<div className="d-flex align-items-end">
				<div style={{ flex: 1 }}>
					<FloatingLabel
						type="text"
						label="Search Webinars by ID or Title"
						value={search_string}
						onChange={(e) => setSearchString(e.target.value)}
					/>
				</div>
				<button className="btn btn-primary py-2" disabled={loading} onClick={refresh}>
					{loading ? (
						<React.Fragment>
							<FontAwesomeIcon icon="sync" className="mr-2" spin />
							Refreshing...
						</React.Fragment>
					) : (
						'Refresh Webinars List'
					)}
				</button>
			</div>

			<Tabs index={hash}>
				<List tab_index={0}>
					{filtered_single_webinars.map((webinar, index) => {
						const webinar_start_time = new Date(webinar.start_time.slice(0, -6))

						return webinar_start_time > new Date() ? (
							<ListElement
								key={index}
								onClick={() =>
									handleChange({
										webinarjam_webinar_id: null,
										webinarjam_type: null,
										bigmarker_webinar_id: webinar.id,
									})
								}
							>
								<h5>{webinar.title}</h5>

								<BigmarkerSingeWebinarItem webinar={webinar} start_time={webinar_start_time} />
							</ListElement>
						) : null
					})}

					{filtered_single_webinars.length === 0 && (
						<ListElement>
							<h5 className="my-3 text-center">Sorry, we cannot find any Webinar with this title.</h5>
						</ListElement>
					)}
				</List>

				<List tab_index={1}>
					{filtered_recurring_webinars.map((webinar, index) => (
						<ListElement
							key={index}
							onClick={() =>
								handleChange({
									webinarjam_webinar_id: null,
									webinarjam_type: null,
									bigmarker_webinar_id: webinar.id,
								})
							}
						>
							<h5>{webinar.title}</h5>

							<BigmarkerRecurrentWebinarItem webinar={webinar} />
						</ListElement>
					))}
					{filtered_recurring_webinars.length === 0 && (
						<ListElement>
							<h5 className="my-3 text-center">Sorry, we cannot find any Webinar with this title.</h5>
						</ListElement>
					)}
				</List>
			</Tabs>
		</React.Fragment>
	)
}

function WebinarJamList({ list, handleChange }) {
	const [search_string, setSearchString] = useState('')

	const filtered_list = list.filter((webinar) => webinar.title.includes(search_string))

	return (
		<React.Fragment>
			<FloatingLabel
				type="text"
				label="Search Webinars by Title"
				value={search_string}
				onChange={(e) => setSearchString(e.target.value)}
			/>
			<List>
				{filtered_list.map((webinar, index) => (
					<ListElement
						key={index}
						onClick={() =>
							handleChange({
								bigmarker_webinar_id: null,
								webinarjam_webinar_id: String(webinar.webinar_id),
								webinarjam_type: webinar.type,
							})
						}
					>
						<h5>{webinar.name}</h5>
						<div>
							<p>{webinar.description}</p>
							<p>
								Start Time: <b>{webinar.schedules.join(', ')}</b>
							</p>
						</div>
					</ListElement>
				))}
			</List>
		</React.Fragment>
	)
}

function AvailableWebinars({ campaign, user, onChange }) {
	const [show, setShow] = useState(false)
	const [current_tab, setTab] = useState(0)

	const { bigmarker_webinar_id, webinarjam_webinar_id } = campaign

	const {
		data: {
			user: {
				available_webinars: { bigmarker_list, webinarjam_list },
			},
		},
		loading,
		refetch,
	} = useQuery(GET_AVAILABLE_WEBINARS, {
		variables: { user_id: user.id },
		default_data: {
			user: {
				webinarjam_webinars: [],
				available_webinars: {
					bigmarker_list: { single_webinars: [], recurring_webinars: [] },
					webinarjam_list: [],
				},
			},
		},
	})

	const bigmarker_complete_list = [
		...(bigmarker_list?.single_webinars || []),
		...(bigmarker_list?.recurring_webinars || []),
	]

	const webinars_to_show = bigmarker_complete_list.length > 0 || webinarjam_list.length > 0
	const only_bigmarker = bigmarker_complete_list.length > 0 && webinarjam_list.length === 0
	const only_webinarjam = bigmarker_complete_list.length === 0 && webinarjam_list.length > 0

	const selected_webinar = useMemo(() => {
		if (bigmarker_webinar_id) {
			return bigmarker_complete_list?.find((w) => String(w.id) === bigmarker_webinar_id)
		}

		if (webinarjam_webinar_id) {
			return webinarjam_list.find((w) => String(w.webinar_id) === webinarjam_webinar_id)
		}

		return null
	}, [bigmarker_webinar_id, webinarjam_webinar_id, bigmarker_list, webinarjam_list])

	const handleChange = (new_values) => {
		onChange(new_values)
		setShow(false)
	}

	const handleClear = () => {
		onChange({
			bigmarker_webinar_id: null,
			webinarjam_webinar_id: null,
			webinarjam_type: null,
		})
	}

	return (
		<div>
			<div className="form-text text-muted">Webinar</div>
			{selected_webinar && (
				<React.Fragment>
					{bigmarker_webinar_id ? (
						<div className="d-flex justify-content-between border py-3 px-4 w-100">
							<SelectedContainer>
								<img src={bigmarker_logo} alt="BigMarker Logo" style={{ height: '1.6rem' }} />

								<div className="ml-3 px-3">
									<div className="btn btn-link p-0 mb-2" onClick={() => setShow(true)}>
										<strong>{selected_webinar.title}</strong>
									</div>

									{selected_webinar.child_webinars ? (
										<BigmarkerRecurrentWebinarItem webinar={selected_webinar} />
									) : (
										<BigmarkerSingeWebinarItem webinar={selected_webinar} />
									)}
								</div>
							</SelectedContainer>

							<FontAwesomeIcon
								icon="times"
								className="text-muted float-right clickable mt-1"
								onClick={handleClear}
							/>
						</div>
					) : (
						<div className="d-flex justify-content-between border p-3">
							<SelectedContainer>
								<img src={webinarjam_logo} alt="WebinarJam Logo" style={{ height: '1.6rem' }} />

								<div className="ml-4">
									<div className="btn btn-link" onClick={() => setShow(true)}>
										<strong>{selected_webinar.name}</strong>
									</div>

									<p>{selected_webinar.description}</p>

									<p>
										Start Time: <b>{selected_webinar.schedules.join(', ')}.</b>
									</p>
								</div>
							</SelectedContainer>

							<FontAwesomeIcon
								icon="times"
								className="text-muted float-right clickable"
								onClick={handleClear}
							/>
						</div>
					)}
				</React.Fragment>
			)}

			{!selected_webinar && (
				<button className="btn-unstyled" type="button" onClick={() => setShow(true)}>
					Select Webinar
				</button>
			)}
			<Modal show={show} modalDidClose={() => setShow(false)} style={{ maxHeight: '700px' }}>
				<h1 className="text-center">
					<img src={WebinarIcon} style={{ height: '2.4rem', marginRight: '15px' }} alt="" />
					Select a Webinar
				</h1>

				{webinars_to_show ? (
					<React.Fragment>
						{!only_bigmarker && !only_webinarjam && (
							<div className="d-flex justify-content-center">
								<TabsBar index={current_tab}>
									<TabButton onClick={() => setTab(0)}>
										<span>BigMarker</span>
									</TabButton>
									<TabButton onClick={() => setTab(1)}>
										<span>WebinarJam</span>
									</TabButton>
								</TabsBar>
							</div>
						)}

						{/* eslint-disable-next-line no-nested-ternary */}
						{only_bigmarker ? (
							<BigMarkerList
								list={bigmarker_list}
								handleChange={handleChange}
								user_id={user.id}
								refetch={refetch}
							/>
						) : only_webinarjam ? (
							<WebinarJamList list={webinarjam_list} handleChange={handleChange} />
						) : (
							<Tabs index={current_tab} virtualized>
								<div tab_index={0}>
									<BigMarkerList
										list={bigmarker_list}
										handleChange={handleChange}
										user_id={user.id}
										refetch={refetch}
									/>
								</div>
								<div tab_index={0}>
									<WebinarJamList list={webinarjam_list} handleChange={handleChange} />
								</div>
							</Tabs>
						)}
					</React.Fragment>
				) : (
					<React.Fragment>
						{loading ? (
							<div className="d-flex align-items-center" style={{ width: '100%', height: '200px' }}>
								<Spinner />
							</div>
						) : (
							<div className="alert alert-info  text-center my-4">
								Sorry, we couldn find any Webinars to Select, please check your integrations.
							</div>
						)}
					</React.Fragment>
				)}
			</Modal>
		</div>
	)
}

AvailableWebinars.propTypes = {
	user: PropTypes.object,
	campaign: PropTypes.object,
	onChange: PropTypes.func,
}

WebinarJamList.propTypes = {
	list: PropTypes.array,
	handleChange: PropTypes.func,
}

BigMarkerList.propTypes = {
	list: PropTypes.array,
	user_id: PropTypes.string,
	handleChange: PropTypes.func,
	refetch: PropTypes.func,
}

BigmarkerSingeWebinarItem.propTypes = {
	webinar: PropTypes.object,
	start_time: PropTypes.string,
}

BigmarkerRecurrentWebinarItem.propTypes = {
	webinar: PropTypes.object,
}

export default AvailableWebinars
