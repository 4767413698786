/**
 *
 * Campaigns/Create/Form
 *
 */

import React, { useState, useEffect } from 'react'

// NPM Libraries
import CurrencyInput from 'react-currency-input'
import Toggle from 'react-toggle'
import { useMergeState, useCampaignTopicOptions } from 'hooks'
import { withCurrentUser } from 'currentUserContext'

// Components
import MarkdownEditor from 'components/MarkdownEditor'
import CarouselImages from 'components/Campaigns/Form/CarouselImages'
import UploadCampaignImage from 'components/UploadCampaignImage'
import FloatingLabel from '@joeyparis/react-floating-label-input'
import Checkbox from 'components/Checkbox'
import DragAndDropListSingleInput from 'components/DragAndDropList'
import SelectUser from 'components/Campaigns/Form/SelectUser'
import TemplateSelector from 'components/Campaigns/Form/TemplateSelector'
import LandingPageBackgroundSelector from 'components/LandingPageBackgroundSelector'
import ObjectiveSelector from 'components/Campaigns/Form/ObjectiveSelector'
import JobCodes from 'components/Campaigns/Form/JobCodes'
import Modal from 'components/Modal'
import EmailTemplates from 'components/CampaignEmailTemplates/EmailTemplates'
import Sequences from 'components/Campaigns/Form/Sequences'
import AvailableWebinars from 'components/Integrations/AvailableWebinars'
import Description2 from 'components/Campaigns/Form/Description2'
import HeaderTags from 'components/Campaigns/Form/HeaderTags'
import OptIn from 'components/Campaigns/Form/OptIn'
import DisclaimerEdit from 'components/LandingPagePreferences/DisclaimerEdit'
import ChartIcon from 'components/Campaigns/Form/Chart.svg'
import { APPOINTMENT_DURATION_OPTIONS } from 'components/AccountPreferences/AppointmentDuration'
import { Container } from 'utils/custom-styled-components'

const appointment_duration_options = [{ text: 'Use Default', value: null }].concat(APPOINTMENT_DURATION_OPTIONS)

const TEST_CAMPAIGN = [
	{value: '', text: ''},
	{value: 'beta', text: 'Beta'},
	{value: 'investwise', text: 'IWF'},
	{value: 'marketing_rd', text: 'Marketing - R&D'},
	{value: 'marketing_no_rd', text: 'Marketing - no R&D'},
]

function Form({ disabled, handleSubmit, current_user, action, campaign = {}, title }) {
	const [campaign_state, setCampaignState] = useMergeState(campaign)

	const [show_email_templates, setShowEmailTemplates] = useState(false)
	const [show_advance_fields, setShowAdvanceFields] = useState(false)
	const [show_landing_page_backgrounds, setShowLandingPageBackgrounds] = useState(false)

	const campaign_topic_options = useCampaignTopicOptions()
	const current_user_is_evan = current_user.id === '45bf44e3-ec44-4046-b2b4-293e3bbfdb55'

	useEffect(() => {
		if (campaign_state.id !== campaign.id) {
			setCampaignState(campaign)
		}
	}, [campaign.id])

	const has_objective = [0, 1, 2, 3, 5].includes(campaign_state.objective_index)

	const handleInputChange = (e) => {
		const key = e.target.name
		let { value } = e.target

		if (key === 'redirect') {
			value = e.target.checked
		}

		setCampaignState({ [key]: value })
	}

	/* eslint-disable */
	const onSubmit = (e) => {
		e.preventDefault()
		const valid = has_objective && !!campaign_state.user
		if (valid) {
			if (
				!campaign.ready &&
				campaign_state.ready &&
				!confirm(
					'This campaign will be saved as ready and the campaign owner will immediately be charged for this campaign.' +
					' Once a campaign has been saved as ready it cannot be marked as unready.',
				)
			) {
				return false
			}

			const campaign_fields = {
				...campaign_state,
				contact_price: campaign_state.contact_price
					? parseFloat(String(campaign_state.contact_price).split('$').pop(), 10)
					: 0.0,
				guest_price: campaign_state.guest_price
					? parseFloat(String(campaign_state.guest_price).split('$').pop(), 10)
					: 0.0,
				reach_override: parseInt(campaign_state.reach_override, 10),
				objective: campaign_state.objective_index,
				user_id: campaign_state.user.id,
				sequence_id: campaign_state.sequence_id,
				appointment_duration: campaign_state._appointment_duration,
			}

			handleSubmit(campaign_fields)
		}
		return false
	}
	/* eslint-enable */

	const handleSelectUser = (user) => {
		let new_campaing_state = { user, user_id: user.id }

		if (action === 'Create') {
			new_campaing_state = {
				...new_campaing_state,
				questions: user.default_campaign_questions,
				questions_required: user.default_campaign_questions_required,
			}
		}

		setCampaignState(new_campaing_state)
	}

	const can_be_ready =
		campaign_state.topic &&
		campaign_state.headline &&
		(campaign_state.description?.length > 0 || campaign_state.highlights?.filter((h) => h).length > 0)

	return (
		<Container>
			<form onSubmit={onSubmit}>
				<button
					type="submit"
					className="btn btn-success rounded "
					style={{
						position: 'fixed',
						right: '2em',
						top: '1em',
						zIndex: 10,
					}}
				>
					Save Campaign
				</button>
				<div>
					<div className="row">
						<div className="col-12 text-center py-4">
							<h1>
								<img src={ChartIcon} style={{ height: '3rem', marginRight: '20px' }} alt="" />
								{title || `${action} a campaign`}
							</h1>
						</div>
					</div>

					{current_user.is_consultant && !!campaign.id && (
						<div className="row">
							<div className="col text-center pb-2">
								<a href={`${process.env.API_URL}/campaigns/${campaign.id}/copy`}>Copy this campaign</a>
								<div style={{ position: 'absolute', right: 0, top: 0 }}>
									<Toggle
										checked={campaign_state.ready}
										onChange={(e) => setCampaignState({ ready: e.target.checked })}
										disabled={!can_be_ready || disabled || campaign.ready}
									/>{' '}
									Ready{campaign_state.ready ? '!' : '?'}
								</div>
							</div>
						</div>
					)}

					<div
						className="mb-4 d-flex flex-wrap justify-content-around"
						style={{
							borderTop: '1px solid #eee',
							borderBottom: '1px solid #eee',
						}}
					>
						<SelectUser
							setUser={handleSelectUser}
							user={campaign_state.user}
							disabled={disabled || campaign.default_appointment_setter}
						/>
						<ObjectiveSelector
							objective_index={campaign_state.objective_index}
							setObjectiveIndex={(objective_index) => setCampaignState({ objective_index })}
							call_to_action={campaign_state.call_to_action}
							setCallToAction={(call_to_action) => setCampaignState({ call_to_action })}
							disabled={disabled || campaign.default_appointment_setter}
						/>
						<Sequences
							onSelect={(sequence) =>
								setCampaignState({
									sequence,
									sequence_id: sequence ? sequence.id : null,
								})
							}
							campaign={campaign_state}
						/>
						<TemplateSelector campaign={campaign_state} setCampaignState={setCampaignState} />
					</div>

					<div className="row">
						<div className="col-md-6">
							<FloatingLabel
								label="Topic"
								type="select"
								name="topic"
								value={campaign_state.topic || ''}
								options={campaign_topic_options}
								onChange={handleInputChange}
								prevent_autofill
								required
								disabled={disabled || campaign.default_appointment_setter}
							/>
							<FloatingLabel
								label="Campaign Name"
								type="text"
								name="name"
								value={campaign_state.name || ''}
								onChange={handleInputChange}
								required
								disabled={disabled || campaign.default_appointment_setter}
							/>
							<FloatingLabel
								label="Video Link"
								type="url"
								name="video_embed_link"
								value={campaign_state.video_embed_link || ''}
								onChange={handleInputChange}
								id="video link"
								disabled={disabled}
							/>
							{!campaign.default_appointment_setter && (
								<div className="row">
									<div className="col-4">
										{(current_user.is_artist || current_user_is_evan) && (
											<JobCodes
												setJobCodes={(job_codes) => setCampaignState({ job_codes })}
												job_codes={campaign_state.job_codes}
												disabled={disabled}
											/>
										)}
									</div>
									<div className="col-4">
										<div className="position-relative" style={{ height: '67px' }}>
											<label>
												<input
													name="is_meal_event"
													type="radio"
													value
													onChange={() =>
														setCampaignState({
															is_meal_event: true,
														})
													}
													checked={campaign_state.is_meal_event === true}
													style={{
														position: 'absolute',
														top: '40px',
														left: '10px',
														zIndex: '2',
													}}
													required
												/>
												<div
													className="form-text text-muted"
													style={{
														position: 'absolute',
														top: 30,
														paddingLeft: '35px',
														width: '100%',
													}}
												>
													Meal Events
												</div>
											</label>
										</div>
									</div>
									<div className="col-4">
										<div className="position-relative" style={{ height: '67px' }}>
											<label>
												<input
													name="is_meal_event"
													type="radio"
													value={false}
													onChange={() =>
														setCampaignState({
															is_meal_event: false,
														})
													}
													checked={campaign_state.is_meal_event === false}
													style={{
														position: 'absolute',
														top: '40px',
														left: '10px',
														zIndex: '2',
													}}
													required
												/>
												<div
													className="form-text text-muted"
													style={{
														position: 'absolute',
														top: 30,
														paddingLeft: '35px',
														width: '100%',
													}}
												>
													Non-Meal Events
												</div>
											</label>
										</div>
									</div>
								</div>
							)}
						</div>

						<div className="col-md-6 pb-3">
							<FloatingLabel
								label="Text above headline"
								type="text"
								name="call_to_action"
								value={campaign_state.call_to_action || ''}
								onChange={handleInputChange}
								disabled={disabled}
							/>
							<FloatingLabel
								label="Headline"
								type="text"
								name="headline"
								value={campaign_state.headline || ''}
								onChange={handleInputChange}
								required
								disabled={disabled}
							/>
							<FloatingLabel
								label="Text below headline"
								type="text"
								name="sub_headline"
								value={campaign_state.sub_headline || ''}
								onChange={handleInputChange}
								disabled={disabled}
							/>
							<div className="position-relative" style={{ height: '67px' }}>
								<Checkbox
									checked={campaign_state.redirect}
									onChange={(redirect) => setCampaignState({ redirect })}
									disabled={disabled}
									style={{
										position: 'absolute',
										top: 35,
										left: 10,
										zIndex: 2,
									}}
								/>
								<FloatingLabel
									label="Redirect URL"
									type="url"
									name="redirect_url"
									value={campaign_state.redirect_url || ''}
									onChange={handleInputChange}
									required={campaign_state.redirect}
									containerStyle={{
										position: 'absolute',
										top: 0,
										paddingLeft: '35px',
										width: '100%',
									}}
									id="custom_landing_page_url"
									disabled={disabled || !campaign_state.redirect}
								/>
							</div>
							<div
								style={{ position: 'absolute', right: '1em', zIndex: 2 }}
								hidden={!campaign_state.redirect}
							>
								<Checkbox
									label="Redirect After Registration"
									checked={campaign_state.redirect_after_registration}
									onChange={(redirect_after_registration) =>
										setCampaignState({ redirect_after_registration })
									}
									disabled={disabled || !campaign_state.redirect}
								/>
							</div>
						</div>
						<div className="col-12">
							<div className="form-group">
								<div className="form-text text-muted mt-3 mb-2">Description</div>
								<MarkdownEditor
									value={campaign_state.description}
									setValue={(description) => setCampaignState({ description })}
									disabled={disabled}
								/>
							</div>
							<div className="form-group">
								<div className="form-text text-muted">Highlights</div>
								<DragAndDropListSingleInput
									items={campaign_state.highlights || []}
									setItems={(highlights) => setCampaignState({ highlights })}
									placeholder="Start typing..."
									disabled={disabled}
								/>
							</div>
							<Description2
								campaign_state={campaign_state}
								setCampaignState={setCampaignState}
								disabled={disabled}
							/>
							<div className="mt-2 form-group">
								<div className="form-text text-muted d-flex">
									<span>Additional Questions</span>
									{campaign_state.questions?.filter((q) => q.length > 0)?.length > 0 && (
										<Checkbox
											label="Required?"
											checked={campaign_state.questions_required}
											onChange={() =>
												setCampaignState({
													questions_required: !campaign_state.questions_required,
												})
											}
											className="ml-5"
										/>
									)}
								</div>
								<DragAndDropListSingleInput
									items={campaign_state.questions || []}
									setItems={(questions) => setCampaignState({ questions })}
									placeholder="Start typing..."
									disabled={disabled}
								/>
							</div>
							{campaign.topic?.includes('567') && (
								<div className="mt-2 form-group">
									<FloatingLabel
										label="Demio Webinar Key"
										type="text"
										name="demio_webinar_key"
										value={campaign_state.demio_webinar_key || ''}
										onChange={handleInputChange}
										id="demio_webinar_key"
										disabled={disabled}
									/>
								</div>
							)}
							{(campaign.user?.webinarjam_api_key || campaign.user?.bigmarker_activated) &&
								current_user.is_consultant && (
								<div className="mt-2 form-group">
									<AvailableWebinars
										campaign={campaign_state}
										user={campaign_state.user}
										onChange={(new_values) => setCampaignState(new_values)}
									/>
								</div>
							)}
						</div>
					</div>
					{action === 'Edit' && (
						<React.Fragment>
							<div className="row mt-4">
								<div className="form-group col-lg-6">
									<OptIn
										user_id={campaign_state.user_id}
										campaign_state={campaign_state}
										mergeState={setCampaignState}
										disabled={disabled}
									/>
								</div>
								<div className="form-group col-lg-6">
									<FloatingLabel
										type="select"
										label="Appointment Duration"
										value={
											/* eslint-disable */
											appointment_duration_options.find(
												(option) => option.value === campaign_state._appointment_duration,
											)?.text || ''
											/* eslint-enable */
										}
										name="_appointment_duration"
										onChange={handleInputChange}
										options={appointment_duration_options}
										disabled={disabled}
									/>
								</div>
							</div>
							{!campaign.default_appointment_setter && (
								<div className="row">
									<div className="form-group col-lg-6">
										<label className="form-text text-muted" htmlFor="phone_greeting">
											Phone Greeting
										</label>
										<textarea
											rows={3}
											className="form-control"
											name="phone_greeting"
											value={campaign_state.phone_greeting}
											onChange={handleInputChange}
											disabled={!current_user.is_consultant || disabled}
										/>
									</div>
									<div className="form-group col-lg-6">
										<label className="form-text text-muted" htmlFor="phone_closing">
											Phone Closing
										</label>
										<textarea
											rows={3}
											className="form-control"
											name="phone_closing"
											value={campaign_state.phone_closing}
											onChange={handleInputChange}
											disabled={!current_user.is_consultant || disabled}
										/>
									</div>
									<div className="form-group col-lg-6">
										<label className="form-text text-muted" htmlFor="operator_notes">
											Operator Notes
										</label>
										<textarea
											rows={3}
											className="form-control"
											name="operator_notes"
											value={campaign_state.operator_notes || ''}
											onChange={handleInputChange}
											disabled={!current_user.is_consultant || disabled}
										/>
									</div>
									{campaign_state.objective_index === 0 && (
										<div className="form-group col-lg-6">
											<label className="form-text text-muted" htmlFor="waitlist_phone_closing">
												Waitlist Phone Closing
											</label>
											<textarea
												rows={3}
												className="form-control"
												name="waitlist_phone_closing"
												value={campaign_state.waitlist_phone_closing}
												onChange={handleInputChange}
												disabled={!current_user.is_consultant || disabled}
											/>
										</div>
									)}
								</div>
							)}
							{campaign.user.merchant_account_verified && (
								<div className="form-group col-12 d-flex justify-content-start">
									<label className="form-text text-muted" htmlFor="contact_price">
										Contact Price
									</label>
									<CurrencyInput
										type="text"
										className="form-control ml-2"
										style={{
											width: '85px',
											marginRight: '60px',
										}}
										name="contact_price"
										value={campaign_state.contact_price}
										onChangeEvent={handleInputChange}
										prefix="$"
										disabled={disabled}
									/>
									<label className="form-text text-muted" htmlFor="guest_price">
										Guest Price
									</label>
									<CurrencyInput
										type="text"
										className="form-control ml-2"
										style={{ width: '85px' }}
										name="guest_price"
										value={campaign_state.guest_price}
										onChangeEvent={handleInputChange}
										prefix="$"
										disabled={disabled}
									/>
								</div>
							)}
							<UploadCampaignImage
								campaign={campaign}
								onChange={(campaign_image_url) => setCampaignState({ campaign_image_url })}
							/>
						</React.Fragment>
					)}
					{action === 'Edit' && (
						<CarouselImages
							campaign_state={campaign_state}
							setImages={(images) => setCampaignState({ carousel_images: images })}
						/>
					)}
					<div className="d-flex mt-4 mb-5">
						<button
							type="button"
							className="btn btn-primary rounded mr-3"
							onClick={() => setShowLandingPageBackgrounds(true)}
						>
							Landing Page Background
						</button>
						{action === 'Edit' && (
							<React.Fragment>
								{campaign.email_templates.length > 0 && (
									<button
										type="button"
										className="btn btn-primary rounded ml-3"
										onClick={() => setShowEmailTemplates(!show_email_templates)}
										disabled={disabled}
									>
										Email Templates
									</button>
								)}
								{current_user.is_artist && (
									<button
										type="button"
										className="btn btn-primary rounded ml-3"
										onClick={() => setShowAdvanceFields(!show_advance_fields)}
									>
										Advance Fields
									</button>
								)}
							</React.Fragment>
						)}
					</div>
					{show_advance_fields && (
						<React.Fragment>
							<div className="row">
								{current_user.is_partner && !campaign.default_appointment_setter && (
									<div className="col-sm-6 col-md-4">
										<div className="form-group">
											<label className="form-text text-muted" htmlFor="reach_override">
												Reach
											</label>
											<input
												type="number"
												className="form-control"
												name="reach_override"
												value={campaign_state.reach_override}
												onChange={handleInputChange}
												disabled={disabled}
											/>
											<small className="form-text text-muted">
												Set to -1 to disable override.
											</small>
										</div>
									</div>
								)}
							</div>
							<div className="row">
								<div className="col-sm-6 col-md-4">
									<div className="form-group">
										<label className="form-text text-muted" htmlFor="skai_default_kpid">
										Skai Default KPID
										</label>
										<input
											type="text"
											className="form-control"
											name="skai_default_kpid"
											value={campaign_state.skai_default_kpid}
											onChange={handleInputChange}
											disabled={disabled}
										/>
									</div>
								</div>
							</div>
							<div className="row">
                                <div className="col-sm-6 col-md-4">
                                    <div className="form-group">
									<FloatingLabel
										type="select"
										label="Test Campaign"
										value={campaign_state.test_campaign}
										name="test_campaign"
										onChange={handleInputChange}
										options={TEST_CAMPAIGN}
										disabled={disabled}
									/>
                                    </div>
                                </div>
                            </div>
							{/* <div className="row">
								<div className="col-sm-6 col-md-4">
									<div className="form-group">
										<label className="form-text text-muted" htmlFor="builder_page_id">
											Builder Page
										</label>
										<input
											type="text"
											className="form-control"
											name="builder_page_id"
											value={campaign_state.builder_page_id}
											onChange={handleInputChange}
											disabled={disabled}
										/>
									</div>
								</div>
							</div> */}
							{current_user.is_god && (
								<HeaderTags
									campaign_state={campaign_state}
									setHeaderTags={(header_tags) => setCampaignState({ header_tags })}
								/>
							)}
							<div className="mt-2 form-group">
								<DisclaimerEdit
									disclaimer_json={campaign_state.disclaimer_json}
									campaign_id={campaign_state.id}
								/>
							</div>
						</React.Fragment>
					)}

					<div className="my-4 text-center">
						<p className="text-danger ml-4">
							{!campaign_state.user && 'Please select a campaign owner.   '}
							{!has_objective && 'Please select a campaign type.'}
						</p>
						<button type="submit" className="btn btn-success rounded" data-test="save-campaign">
							Save Campaign
						</button>
					</div>
				</div>
				{campaign.email_templates && campaign.email_templates.length > 0 && (
					<Modal
						show={show_email_templates}
						modalDidClose={() => setShowEmailTemplates(false)}
						style={{
							padding: '2rem 1rem',
							width: 1100,
						}}
					>
						<EmailTemplates
							templates={campaign.email_templates}
							template_defaults={campaign_state.email_template_defaults}
							selectTemplate={(index, value) => {
								setCampaignState({
									email_template_defaults: {
										...campaign_state.email_template_defaults,
										[index]: value,
									},
								})
							}}
							onComplete={() => setShowEmailTemplates(false)}
						/>
					</Modal>
				)}
				<Modal
					show={show_landing_page_backgrounds}
					modalDidClose={() => setShowLandingPageBackgrounds(false)}
					style={{ width: '80%' }}
				>
					<LandingPageBackgroundSelector
						handleInputChange={handleInputChange}
						current_background_id={campaign_state.landing_page_background_id || ''}
						disabled={disabled}
					/>
				</Modal>
			</form>
		</Container>
	)
}

Form.propTypes = {}

export default withCurrentUser(Form)
